import React, { useContext, useCallback } from 'react';
import { FilterContext } from '../context/FilterContext';

const DropdownFilter = React.memo(({ title, filterGroup, counts }) => {
  const { filters, updateFilters, openDropdowns, toggleDropdown } = useContext(FilterContext);

  const isOpen = openDropdowns[filterGroup] || false;
  const filterValues = filters[filterGroup]?.values || {};

  const handleToggle = useCallback(() => {
    toggleDropdown(filterGroup);
  }, [toggleDropdown, filterGroup]);

  const handleFilterChange = useCallback((filter) => {
    updateFilters(filterGroup, filter);
  }, [updateFilters, filterGroup]);

  return (
    <div className="dropdown-filter">
      <button 
        className="title" 
        onClick={handleToggle}
        aria-expanded={isOpen}
        aria-controls={`${filterGroup}-content`}
      >
        <span className="title-text">{title}</span>
        <span className={`dropdown-arrow ${isOpen ? 'open' : ''}`} aria-hidden="true">▼</span>
      </button>
      <div 
        id={`${filterGroup}-content`} 
        className={`content ${isOpen ? 'open' : ''}`} 
        role="group" 
        aria-label={`${title} options`}
      >
        {Object.keys(filterValues)
          .filter(filter => filter && filter.trim() !== '' && filter !== 'undefined')
          .sort((a, b) => a.localeCompare(b))
          .map((filter) => (
            <div key={filter} className="filter-menu">
              <label>
                <input
                  type="checkbox"
                  checked={filterValues[filter]}
                  onChange={() => handleFilterChange(filter)}
                />
                {filter} ({counts[filter] ?? 0})
              </label>
            </div>
          ))}
      </div>
    </div>
  );
});

export default DropdownFilter;