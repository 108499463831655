import React, { useCallback, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

const Paginate = React.memo(({ postsPerPage, totalPosts, onPageChange }) => {
	const [searchParams, setSearchParams] = useSearchParams();
	const currentPage = parseInt(searchParams.get('page') || '1', 10);

	const maxPages = Math.ceil(totalPosts / postsPerPage);
	const displayPages = Math.min(7, maxPages);
	const middlePage = Math.floor(displayPages / 2);
	let startPage = currentPage - middlePage;
	let endPage = currentPage + middlePage;

	if (maxPages <= displayPages) {
		startPage = 1;
		endPage = maxPages;
	} else if (currentPage <= middlePage) {
		startPage = 1;
		endPage = displayPages;
	} else if (currentPage >= maxPages - middlePage) {
		startPage = maxPages - displayPages + 1;
		endPage = maxPages;
	}

	const handlePageClick = useCallback((pageNumber) => {
		if (pageNumber !== currentPage) {
			onPageChange(pageNumber);
			setSearchParams({ page: pageNumber.toString() });
		}
	}, [onPageChange, currentPage, setSearchParams]);

	const handlePreviousPageClick = useCallback(() => {
		if (currentPage > 1) {
			const newPage = currentPage - 1;
			onPageChange(newPage);
			setSearchParams({ page: newPage.toString() });
		}
	}, [onPageChange, currentPage, setSearchParams]);

	const handleNextPageClick = useCallback(() => {
		if (currentPage < maxPages) {
			const newPage = currentPage + 1;
			onPageChange(newPage);
			setSearchParams({ page: newPage.toString() });
		}
	}, [onPageChange, currentPage, maxPages, setSearchParams]);

	useEffect(() => {
		onPageChange(currentPage);
	}, [currentPage, onPageChange]);

	const pageNumbers = [];
	for (let i = startPage; i <= endPage; i++) {
		pageNumbers.push(i);
	}

	return (
		<div className={`pagination-container ${maxPages === 0 ? 'hidden' : ''}`}>
				<ul className="pagination">
					<li 
						onClick={handlePreviousPageClick} 
						className={`arrow ${currentPage === 1 ? 'disabled' : ''}`}>
						←
					</li>
					{pageNumbers.map((number) => (
						<li
							key={number}
							onClick={() => handlePageClick(number)}
							className={`page-number ${number === currentPage ? 'current-page' : ''}`}
						>
							{number}
						</li>
					))}
					<li 
						onClick={handleNextPageClick} 
						className={`arrow ${currentPage === maxPages ? 'disabled' : ''}`}>
						→
					</li>
				</ul>
		</div>
	);
});

export default Paginate;
