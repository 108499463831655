import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../components/Navbar';
import elikoLogo from '../assets/eliko-logo.png';

const Contact = () => {
  return (
    <div className="container">
      <div className="content-wrapper">
        <div className="header-bar">
          <div className="logo">
            <Link to="/">
              <img src={elikoLogo} alt="Eliko Rug Gallery" className="logo-img" />
            </Link>
          </div>
          <Navbar />
        </div>
        <div className="contact-content">
          <h1>Contact</h1>
          <p className="contact-intro">Contact us to discuss your design needs. One of our representatives will be happy to help.</p>
          
          <div className="contact-info">
            <h2>Eliko Rug Gallery</h2>
            <p>152 Madison Ave., 6th Floor</p>
            <p>New York NY 10016</p>
            <p><a href="https://www.elikoruggallery.com" target="_blank" rel="noopener noreferrer">www.elikoruggallery.com</a></p>
            <p>Tel. <a href="tel:2123926655">212-392-6655</a></p>
            <p>Email: <a href="mailto:info@elikoruggallery.com">info@elikoruggallery.com</a></p>
            <p>Instagram: <a href="https://www.instagram.com/elikoruggalleryofficial" target="_blank" rel="noopener noreferrer">elikoruggalleryofficial</a></p>
          </div>
        </div>
      </div>
      <footer className="page-footer">
        <div className="footer-content">
          <div className="column">
            <h3 className="footer-header">Company</h3>
            <a href="https://www.elikoruggallery.com" target="_blank" rel="noopener noreferrer">Official Website</a>
          </div>
          <div className="column">
            <h3 className="footer-header">Connect</h3>
            <a href="https://www.instagram.com/elikoruggalleryofficial" target="_blank" rel="noopener noreferrer">Instagram</a>
          </div>
        </div>
        <div className="copyright-notice">
          © 2024 Eliko Rug Gallery. All Rights Reserved.
        </div>
      </footer>
    </div>
  );
};

export default Contact;
