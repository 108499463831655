import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../components/Navbar';
import elikoLogo from '../assets/eliko-logo.png';

const Process = () => {
  return (
    <div className="container">
      <div className="content-wrapper">
        <div className="header-bar">
          <div className="logo">
            <Link to="/">
              <img
                src={elikoLogo}
                alt="Rug Samples Webpage"
                className="logo-img"
              />
            </Link>
          </div>
          <Navbar />
        </div>
        <div className="process-content">
          <h1>Process</h1>
          <div className="process-info">
            <h3>Browse</h3>
            <p>Browse through our online catalogue of samples using the filters to narrow down your search. Filters Include: Design, Color, Texture & Fiber.</p>
            
            <h3>Select</h3>
            <p>Make a selection of the samples and add to your wishlist. You can save your selection as a pdf and then share it with us by email.</p>
            
            <h3>Request</h3>
            <p>Contact us for checking the samples out on loan and pricing for your desired size. Pricing is calculated per square feet.</p>
            
            <h3>Customize</h3>
            <p>Customization of the samples is our specialty. Changes of color, texture & designs are welcome.</p>
            
            <h3>Notes:</h3>
            <ul>
              <li>All rugs are made to order. They are hand-knotted and not machine made- lead times will vary.</li>
              <li>We do not carry stock pieces of the samples pictured in this catalogue. For our in stock vintage & new rugs visit us at: <a href="https://www.elikoruggallery.com" target="_blank" rel="noopener noreferrer">elikoruggallery.com</a></li>
            </ul>
          </div>
        </div>
      </div>
      <footer className="page-footer">
        <div className="footer-content">
          <div className="column">
            <h3 className="footer-header">Company</h3>
            <a href="https://www.elikoruggallery.com" target="_blank" rel="noopener noreferrer">Official Website</a>
          </div>
          <div className="column">
            <h3 className="footer-header">Connect</h3>
            <a href="https://www.instagram.com/elikoruggalleryofficial?igsh=MTdhZ3cxMzBtM3d0aw%3D%3D" target="_blank" rel="noopener noreferrer">Instagram</a>
          </div>
        </div>
        <div className="copyright-notice">
          © 2024 Eliko Rug Gallery. All Rights Reserved.
        </div>
      </footer>
    </div>
  );
};

export default Process;
