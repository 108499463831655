import jsPDF from 'jspdf';

// npm install jspdf

const loadImage = (src) => {
  return new Promise((resolve, reject) => {
    const extensions = ['jpg', 'jpeg', 'png'];
    const loadNextExtension = (index) => {
      if (index >= extensions.length) {
        reject(new Error('Failed to load image'));
        return;
      }
      const ext = extensions[index];
      import(`../assets/images/${src}.${ext}`)
        .then(image => {
          const img = new Image();
          img.onload = () => resolve(img);
          img.onerror = () => loadNextExtension(index + 1);
          img.src = image.default;
        })
        .catch(() => loadNextExtension(index + 1));
    };
    loadNextExtension(0);
  });
};

export const generatePDF = async (wishlist) => {
  const doc = new jsPDF();
  const pageWidth = doc.internal.pageSize.width;
  const pageHeight = doc.internal.pageSize.height;
  const margin = 10;
  const imageWidth = (pageWidth - 3 * margin) / 2;
  const imageHeight = (pageHeight - 10 * margin) / 3;
  const textSpacing = 6;

  let yPosition = margin;
  let xPosition = margin;

  for (let i = 0; i < wishlist.length; i++) {
    const item = wishlist[i];

    if (i > 0 && i % 6 === 0) {
      doc.addPage();
      yPosition = margin;
      xPosition = margin;
    }

    try {
      const img = await loadImage(item.src);
      doc.addImage(img, 'JPEG', xPosition, yPosition, imageWidth, imageHeight - 2);
    } catch (error) {
      console.error(`Failed to load image for ${item.name}:`, error);
      doc.setFontSize(12);
      doc.text('Image not available', xPosition + imageWidth / 2, yPosition + (imageHeight - 5) / 2, { align: 'center' });
    }

    doc.setFontSize(11);
    const textY = yPosition + imageHeight + textSpacing;
    const textX = xPosition + imageWidth / 2;

    doc.setFont(undefined, 'bold');
    doc.text(`${item.name}`, textX, textY, { align: 'center' });
    doc.setFont(undefined, 'normal');

    doc.text(item.color2 ? `${item.color1}, ${item.color2}` : `${item.color1}`, textX, textY + 5, { align: 'center' });
    doc.text(`${item.design}`, textX, textY + 10, { align: 'center' });
    if (item.texture && item.fiber) {
      doc.text(`${item.texture}, ${item.fiber}`, textX, textY + 15, { align: 'center' });
    } else if (item.texture) {
      doc.text(`${item.texture}`, textX, textY + 15, { align: 'center' });
    } else if (item.fiber) {
      doc.text(`${item.fiber}`, textX, textY + 15, { align: 'center' });
    }

    if (i % 2 === 0) {
      xPosition += imageWidth + margin;
    } else {
      xPosition = margin;
      yPosition += imageHeight + margin + 16;
    }
  }

  const pdfBlob = doc.output('blob');
  const pdfUrl = URL.createObjectURL(pdfBlob);
  window.open(pdfUrl, '_blank');
};