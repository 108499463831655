export const initialFilters = {
    'Color Family': {
      label: 'Color Family',
      values: {},
    },
    'Design Type': {
      label: 'Design Type',
      values: {},
    },
    'Texture': {
      label: 'Texture',
      values: {},
    },
    'Fiber': {
      label: 'Fiber',
      values: {},
    },
  };