import React from 'react';
import { Link } from 'react-router-dom';
import elikoLogo from '../assets/eliko-logo.png';
import landingImage from '../assets/landing_images/landing1.jpg';

const Home = () => {
  return (
    <div className="container">
      <div className="content-wrapper">
        <div className="header-bar">
          <div className="logo">
            <Link to="/">
              <img
                src={elikoLogo}
                alt="Eliko Custom Rugs"
                className="logo-img"
              />
            </Link>
          </div>
        </div>
        <div className="home-content">
          <h1>Eliko Custom Rugs</h1>
          <nav className="home-nav">
            <Link to="/gallery" className="nav-button">Gallery</Link>
            <Link to="/landing" className="nav-button">About</Link>
            <Link to="/process" className="nav-button">Process</Link>
            <Link to="/contact" className="nav-button">Contact</Link>
          </nav>
          <div className="landing-image">
            <img src={landingImage} alt="Rug samples" className="small-landing-image" />
          </div>
        </div>
      </div>
      <footer className="page-footer">
        <div className="footer-content">
          <div className="column">
            <h3 className="footer-header">Company</h3>
            <a href="https://www.elikoruggallery.com" target="_blank" rel="noopener noreferrer">Official Website</a>
          </div>
          <div className="column">
            <h3 className="footer-header">Connect</h3>
            <a href="https://www.instagram.com/elikoruggalleryofficial?igsh=MTdhZ3cxMzBtM3d0aw%3D%3D" target="_blank" rel="noopener noreferrer">Instagram</a>
          </div>
        </div>
        <div className="copyright-notice">
          © 2024 Eliko Rug Gallery. All Rights Reserved.
        </div>
      </footer>
    </div>
  );
};

export default Home;
