import React, { createContext, useState, useEffect, useCallback } from 'react';
import { initialFilters } from '../utils/constants';

export const FilterContext = createContext();

export const FilterProvider = ({ children }) => {
  const [filters, setFilters] = useState(() => {
    const savedFilters = localStorage.getItem('filters');
    return savedFilters ? JSON.parse(savedFilters) : initialFilters;
  });

  const [openDropdowns, setOpenDropdowns] = useState({});

  useEffect(() => {
    localStorage.setItem('filters', JSON.stringify(filters));
  }, [filters]);

  const updateFilters = useCallback((filterGroup, filterValue) => {
    setFilters((prevFilters) => {
      const newFilters = {
        ...prevFilters,
        [filterGroup]: {
          ...prevFilters[filterGroup],
          values: {
            ...prevFilters[filterGroup].values,
            [filterValue]: !prevFilters[filterGroup].values[filterValue],
          },
        },
      };
      localStorage.setItem('filters', JSON.stringify(newFilters));
      return newFilters;
    });
  }, []);

  const toggleDropdown = useCallback((filterGroup) => {
    setOpenDropdowns((prevOpenDropdowns) => ({
      ...prevOpenDropdowns,
      [filterGroup]: !prevOpenDropdowns[filterGroup],
    }));
  }, []);

  const resetFilters = useCallback(() => {
    setFilters((prevFilters) => {
      const resetFilters = { ...prevFilters };
      Object.keys(resetFilters).forEach((filterGroup) => {
        resetFilters[filterGroup] = {
          ...resetFilters[filterGroup],
          values: Object.keys(resetFilters[filterGroup].values).reduce((acc, key) => {
            acc[key] = false;
            return acc;
          }, {})
        };
      });
      localStorage.setItem('filters', JSON.stringify(resetFilters));
      return resetFilters;
    });
  }, []);

  const initializeFilterValues = useCallback((filterOptions) => {
    setFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };
      Object.keys(filterOptions).forEach((filterGroup) => {
        updatedFilters[filterGroup] = {
          ...updatedFilters[filterGroup],
          values: filterOptions[filterGroup].reduce((acc, option) => {
            acc[option] = prevFilters[filterGroup].values[option] || false;
            return acc;
          }, {}),
        };
      });
      localStorage.setItem('filters', JSON.stringify(updatedFilters));
      return updatedFilters;
    });
  }, []);

  return (
    <FilterContext.Provider value={{
      filters,
      updateFilters,
      openDropdowns,
      toggleDropdown,
      resetFilters,
      initializeFilterValues
    }}>
      {children}
    </FilterContext.Provider>
  );
};
