import React, { useState, useEffect } from 'react';
import { createSearchParams, Link } from 'react-router-dom';

const Image = React.memo(({ img, showCaption = true }) => {
  const [imageSrc, setImageSrc] = useState(null);

  useEffect(() => {
    const loadImage = async () => {
      const extensions = ['jpg', 'jpeg', 'png']; // Add more extensions if needed
      for (const ext of extensions) {
        try {
          const image = await import(`../assets/images/${img.src}.${ext}`);
          setImageSrc(image.default);
          return;
        } catch (error) {
          console.error(`Failed to load image: ${img.src}.${ext}`, error);
        }
      }
      setImageSrc(null); // Set to null if all imports fail
    };

    loadImage();
  }, [img.src]);

  return (
    <Link
      id="imageLink"
      to={{
        pathname: '/about',
        search: createSearchParams({
          src: img.src, // Use the original img.src here
          color1: img.color1,
          color2: img.color2,
          design: img.design,
          name: img.name,
          pattern: img.pattern,
          texture: img.texture,
          fiber: img.fiber,
        }).toString(),
      }}
    >
      <div
        className="rug-sample"
        style={{ backgroundImage: `url(${imageSrc})` }}
        name={img.name}
        alt={img.color1}
      />
      {showCaption && <h3 className="caption">{img.name}</h3>}
    </Link>
  );
});

export default Image;