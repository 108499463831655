import React, { useState, useEffect, useCallback, useContext } from 'react';
import Papa from 'papaparse';
import { Link, useSearchParams } from 'react-router-dom';
import Image from '../components/Image';
import Paginate from '../components/Paginate';
import DropdownFilter from '../components/DropdownFilter';
import { calculateFilterCounts } from '../utils/helpers';
import elikoLogo from '../assets/eliko-logo.png';
import csvFile from '../data/samples.csv';
import Navbar from '../components/Navbar';
import { FilterContext } from '../context/FilterContext';

const Gallery = () => {
  const { filters, resetFilters, initializeFilterValues } = useContext(FilterContext);
  const [searchParams, setSearchParams] = useSearchParams();
  
  const [csvData, setCsvData] = useState([]);
  const [currentPosts, setCurrentPosts] = useState([]);
  const [postsPerPage] = useState(8);
  const [filteredPosts, setFilteredPosts] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  const [colorICounts, setColorICounts] = useState({});
  const [designCounts, setDesignCounts] = useState({});
  const [textureCounts, setTextureCounts] = useState({});
  const [fiberCounts, setFiberCounts] = useState({});

  const memoizedInitializeFilterValues = useCallback(initializeFilterValues, [initializeFilterValues]);

  // Get current page from URL
  const getCurrentPage = useCallback(() => {
    const pageFromUrl = searchParams.get('page');
    return pageFromUrl ? parseInt(pageFromUrl, 10) : 1;
  }, [searchParams]);

  const [currentPage, setCurrentPage] = useState(getCurrentPage());

  const handleSearchChange = useCallback((e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
    setSearchParams({ page: '1' });
  }, [setSearchParams]);

  useEffect(() => {
    Papa.parse(csvFile, {
      download: true,
      header: true,
      complete: (results) => {
        setCsvData(results.data);
        
        // Initialize filter values based on the data
        const filterOptions = {
          'Color Family': [...new Set(results.data.flatMap(item => [item['Color I'], item['Color II']]))],
          'Design Type': [...new Set(results.data.flatMap(item => [item['Design'], item['Pattern']]))],
          'Texture': [...new Set(results.data.map(item => item['Texture']))],
          'Fiber': [...new Set(results.data.map(item => item['Fiber']))],
        };

        // Filter out empty, undefined, or null values
        Object.keys(filterOptions).forEach(key => {
          filterOptions[key] = filterOptions[key].filter(value => value && value.trim() !== '' && value !== 'undefined');
        });

        memoizedInitializeFilterValues(filterOptions);
      },
    });
  }, [memoizedInitializeFilterValues]);

  useEffect(() => {
    const filteredPosts = csvData.filter(post => {
      return (!searchQuery || post.No.toLowerCase().includes(searchQuery.toLowerCase())) &&
        Object.keys(filters).every(filterGroup => {
          const selectedFilters = filters[filterGroup].values;
          const selectedKeys = Object.keys(selectedFilters).filter(key => selectedFilters[key]);

          if (selectedKeys.length === 0) return true;

          if (filterGroup === 'Color Family') {
            return selectedKeys.some(filter => ['Color I', 'Color II'].some(colorField => post[colorField] === filter));
          } else if (filterGroup === 'Design Type') {
            return selectedKeys.some(filter => ['Design', 'Pattern'].some(designField => post[designField] === filter));
          } else {
            return selectedKeys.includes(post[filterGroup]);
          }
        });
    });

    setFilteredPosts(filteredPosts);
  }, [csvData, filters, searchQuery]);

  useEffect(() => {
    const updateCounts = () => {
      setColorICounts(calculateFilterCounts(csvData, 'Color I', filters));
      setDesignCounts(calculateFilterCounts(csvData, 'Design', filters));
      setTextureCounts(calculateFilterCounts(csvData, 'Texture', filters));
      setFiberCounts(calculateFilterCounts(csvData, 'Fiber', filters));
    };

    updateCounts();
  }, [csvData, filters]);

  useEffect(() => {
    setCurrentPosts(
      filteredPosts.slice(
        (currentPage - 1) * postsPerPage,
        currentPage * postsPerPage,
      ),
    );
  }, [currentPage, filteredPosts, postsPerPage]);

  const handlePageChange = useCallback(
    (pageNumber) => {
      setCurrentPage(pageNumber);
      setSearchParams({ page: pageNumber.toString() });
    },
    [setSearchParams]
  );

  return (
    <div className="container">
      <div className="content-wrapper">
        <div className="header-bar">
          <div className="logo">
            <Link to="/">
              <img
                href="/"
                src={elikoLogo}
                alt="Rug Samples Webpage"
                className="logo-img"
              />
            </Link>
          </div>
          <Navbar />
        </div>
        <div className="big-container">
          <div className="filter-container">
            <div className="search-container">
              <label className="title">Search</label>
              <input
                className="search-input"
                type="text"
                value={searchQuery}
                onChange={handleSearchChange}
                placeholder="Type Rug No."
              />
            </div>
            {Object.keys(filters).map((filterGroup) => (
              <DropdownFilter
                key={filterGroup}
                title={filters[filterGroup].label}
                filterGroup={filterGroup}
                counts={
                  filterGroup === 'Color Family'
                    ? colorICounts
                    : filterGroup === 'Design Type'
                      ? designCounts
                      : filterGroup === 'Texture'
                        ? textureCounts
                        : fiberCounts
                }
              />
            ))}

            <button onClick={resetFilters} className="reset-filters-button">
              Reset Filters
            </button>
          </div>
          <div className="gallery-container">
            <div id="gallery">
              {currentPosts.map((data, index) => (
                <Image
                  key={index}
                  img={{
                    src: data.No,
                    name: data.No,
                    color1: data['Color I'],
                    color2: data['Color II'],
                    design: data.Design,
                    pattern: data.Pattern,
                    texture: data.Texture,
                    fiber: data.Fiber,
                  }}
                />
              ))}
            </div>
            <Paginate
              postsPerPage={postsPerPage}
              totalPosts={filteredPosts.length}
              currentPage={currentPage}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
      </div>
      <footer className="page-footer">
        <div className="footer-content">
          <div className="column">
            <h3 className="footer-header">Company</h3>
            <a href="https://www.elikoruggallery.com">Official Website</a>
          </div>
          <div className="column">
            <h3 className="footer-header">Connect</h3>
            <a href="https://www.instagram.com/elikoruggalleryofficial?igsh=MTdhZ3cxMzBtM3d0aw%3D%3D">Instagram</a>
          </div>
        </div>
        <div className="copyright-notice">
          © 2024 Eliko Rug Gallery. All Rights Reserved.
        </div>
      </footer>
    </div>
  );
};

export default Gallery;