export const calculateFilterCounts = (data, filterGroup, filters) => {
  const counts = {};

  data.forEach((item) => {
    const isItemIncluded = Object.keys(filters).every((group) => {
      const selectedFilters = filters[group].values;
      const isSelected = Object.keys(selectedFilters).some((filter) => selectedFilters[filter]);
      if (!isSelected) return true;

      if (group === 'Color Family') {
        return ['Color I', 'Color II'].some((colorField) => selectedFilters[item[colorField]]);
      } else if (group === 'Design Type') {
        return ['Design', 'Pattern'].some((designField) => selectedFilters[item[designField]]);
      } else {
        return selectedFilters[item[group]];
      }
    });

    if (isItemIncluded) {
      const value = item[filterGroup];
      if (value && value.trim() !== '' && value !== 'undefined') {
        counts[value] = (counts[value] || 0) + 1;
      }
    }
  });

  return counts;
};