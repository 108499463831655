// This is the about page from the home page

import React from 'react';
import { Link } from 'react-router-dom';
import elikoLogo from '../assets/eliko-logo.png';
import landing1 from '../assets/landing_images/landing1.jpg';
import landing2 from '../assets/landing_images/landing2.jpg';
import landing3 from '../assets/landing_images/landing3.jpg';
import landing4 from '../assets/landing_images/landing4.jpg';
import Navbar from '../components/Navbar';

const Landing = () => {
  return (
    <div className="landing-container">
      <div className="content-wrapper">
        <div className="header-bar">
          <div className="logo">
            <Link to="/">
              <img
                href="/"
                src={elikoLogo}
                alt="Rug Samples Webpage"
                className="logo-img"
              />
            </Link>
          </div>
          <Navbar />
        </div>
        <div className="content">
          <div className="message">
            <h1>Welcome to Eliko Rug Gallery</h1>
            <p>At Eliko Rug Gallery, we pride ourselves on our extensive expertise in delivering custom order hand-knotted rugs that meet the highest standards of quality and precision. With a deep understanding of intricate design and manufacturing techniques, we work closely with our clients to ensure every rug is tailored to their exact specifications. Our commitment to exceptional customer service means that we guide you through each step of the process, from initial design to the final product, with meticulous attention to detail. Our knowledgeable team collaborate to bring your vision to life, delivering a truly unique rug that enhances your space.
            </p>
          </div>
          <div className="photos">
            <img src={landing1} alt="Rug sample 1" className="landing-image" />
            <img src={landing2} alt="Rug sample 2" className="landing-image" />
            <img src={landing3} alt="Rug sample 3" className="landing-image" />
            <img src={landing4} alt="Rug sample 4" className="landing-image" />
          </div>
        </div>
      </div>
      <footer className="page-footer">
        <div className="footer-content">
          <div className="column">
            <h3 className="footer-header">Company</h3>
            <a href="https://www.elikoruggallery.com" target="_blank" rel="noopener noreferrer">Official Website</a>
          </div>
          <div className="column">
            <h3 className="footer-header">Connect</h3>
            <a href="https://www.instagram.com/elikoruggalleryofficial?igsh=MTdhZ3cxMzBtM3d0aw%3D%3D" target="_blank" rel="noopener noreferrer">Instagram</a>
          </div>
        </div>
        <div className="copyright-notice">
          © 2024 Eliko Rug Gallery. All Rights Reserved.
        </div>
      </footer>
    </div>
  );
}

export default Landing;