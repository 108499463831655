import React, { createContext, useState } from 'react';

export const WishlistContext = createContext();

export const WishlistProvider = ({ children }) => {
  const [wishlist, setWishlist] = useState([]);

  const addToWishlist = (item) => {
    setWishlist((prevWishlist) => [...prevWishlist, item]);
  };

  const removeFromWishlist = (itemToRemove) => {
    setWishlist((prevWishlist) => prevWishlist.filter(item => 
      !(item.src === itemToRemove.src &&
        item.name === itemToRemove.name &&
        item.color1 === itemToRemove.color1 &&
        item.color2 === itemToRemove.color2 &&
        item.design === itemToRemove.design &&
        item.pattern === itemToRemove.pattern &&
        item.texture === itemToRemove.texture &&
        item.fiber === itemToRemove.fiber)
    ));
  };

  const clearWishlist = () => {
    setWishlist([]);
  };

  return (
    <WishlistContext.Provider value={{ wishlist, addToWishlist, removeFromWishlist, clearWishlist }}>
      {children}
    </WishlistContext.Provider>
  );
};